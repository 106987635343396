import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class ApiConectionService {
  URL_BASE = `${environment.URL_BASE}`;

  reload = true;
  constructor(private HTTP: HttpClient) {}

  Login(user) {
    let Headers = new HttpHeaders();
    Headers.append("Access-Control-Allow-Origin", this.URL_BASE);

    return this.HTTP.post(`${this.URL_BASE}/auth/loginAdmin`, user, {
      headers: Headers,
    });
  }

  requestPassword(email) {
    let Headers = new HttpHeaders();
    Headers.append("Access-Control-Allow-Origin", this.URL_BASE);

    return this.HTTP.post(`${this.URL_BASE}/auth/recoverPassword`, email, {
      headers: Headers,
    });
  }

  GetUsers(token) {
    let headersCols = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.HTTP.get(`${this.URL_BASE}/api/users`, {
      headers: headersCols,
    });
  }

  GetPublications(token) {
    let headersCols = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.HTTP.get(`${this.URL_BASE}/api/publications`, {
      headers: headersCols,
    });
  }

  GetAdmins(token) {
    let Headers = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.HTTP.get(`${this.URL_BASE}/api/users/usersadmins`, {
      headers: Headers,
    });
  }

  createAdmins(user, token) {
    let Headers = new HttpHeaders({
      Authorization: `${token}`,
    });

    return this.HTTP.post(`${this.URL_BASE}/api/users/createAdmin`, user, {
      headers: Headers,
    });
  }

  GetUserById(id, token) {
    let Headers = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.HTTP.get(`${this.URL_BASE}/api/users/otherUser/${id}`, {
      headers: Headers,
    });
  }

  GetPublicationsById(id, token) {
    let Headers = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.HTTP.get(
      `${this.URL_BASE}/api/publications/publication/${id}`,
      {
        headers: Headers,
      }
    );
  }

  GetpublicationUserByid(id, token) {
    let Headers = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.HTTP.get(`${this.URL_BASE}/api/publications/otherUser/${id}`, {
      headers: Headers,
    });
  }

  DeletePublicationsById(id, token) {
    let Headers = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.HTTP.delete(`${this.URL_BASE}/api/publications/${id}`, {
      headers: Headers,
    });
  }

  sendNotification(notification, token) {
    let headersCols = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.HTTP.post(
      `${this.URL_BASE}/notification/sendPushNotifications`,
      notification,
      {
        headers: headersCols,
      }
    );
  }

  UpdateUserById(user, token) {
    console.log(user);
    let headersCols = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.HTTP.put(`${this.URL_BASE}/api/users/updateUserAdmin`, user, {
      headers: headersCols,
    });
  }

  adminCreatePublication(Publication, token) {
    return this.HTTP.post(
      `${this.URL_BASE}/notification/adminCreatePublication`,
      Publication,
      {
        headers: { Authorization: token },
      }
    );
  }

  adminCreateNotificacion(Notification, token) {
    return this.HTTP.post(
      `${this.URL_BASE}/notification/adminCreateNotificacion`,
      Notification,
      {
        headers: { Authorization: token },
      }
    );
  }
}
